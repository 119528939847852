.my-miofa-container {
    margin: -10px;
    margin-top: -30px;
    position: relative;
    animation-timing-function: linear;
}


.my-miofa-container img {
    background-repeat: round;
}
