.welcome-container {
    background-color: #0b111c;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-container>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome-stage {
    width: 330px;
    height: 200px;
    position: relative;
    background: url("../assets/prop/welcome/welcome-stand.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome-stage>.mio {
    position: absolute;
    bottom: 50px;
    right: 24%;
}

.welcome-stage>.loading-container {
    position: absolute;
    bottom: 8px;
}

@keyframes hide-welcome-container-animate {
    0% {}

    100% {
        opacity: 0;
    }
}

.curtain-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100;
    overflow: hidden;
}

.mio-stage-curtain {
    width: 100%;
    height: 467px;
    background-color: #111722;
    animation: mio-stage-curtain-animate 3s ease-in 3s;
}

.mio-stage-curtain .curtain {
    opacity: 0;
    flex-direction: column-reverse;
    animation: mio-stage-curtain-animate-show 3s ease-in 3s;
}

.mio-stage-curtain .curtain-frame {
    transform: rotate3d(1, 0, 0, 0deg);
    background-image: linear-gradient(to top, #0b111c, #152138);
    animation: main-stage-horizontal-curtain-frame-animate 3s ease-in 3s;
}

.main-stage-curtain-container {
    width: 100%;
    height: calc(100vh - 467px);
    display: flex;
}

.main-stage-curtain-container>div {
    width: 50%;
    background-color: #0b111c;
}

.main-stage-curtain-container>.left {
    animation: main-stage-left-curtain-animate 3s ease-in, hide-curtain-animate 3s linear 3s;
}

.main-stage-curtain-container>.right {
    animation: main-stage-right-curtain-animate 3s ease-in, hide-curtain-animate 3s linear 3s;
}

.main-stage-curtain-container>.left.curtain {
    flex-direction: row-reverse;
}

.main-stage-curtain-container>.right.curtain {
    flex-direction: row;
}

.main-stage-curtain-container .curtain-frame {
    animation: main-stage-vertical-curtain-frame-animate 3s linear;
}

.main-stage-curtain-container>.left .curtain-frame {
    transform: rotate3d(0, 1, 0, 30deg);
    background-image: linear-gradient(to left, #0b111c, #152138);
}

.main-stage-curtain-container>.right .curtain-frame {
    transform: rotate3d(0, 1, 0, 30deg);
    background-image: linear-gradient(to right, #0b111c, #152138);
}

@keyframes mio-stage-curtain-animate {
    100% {
        transform: translateY(-100%);
    }
}

@keyframes main-stage-left-curtain-animate {
    100% {
        transform: translateX(-100%);
        background-color: #111722d5;
    }
}

@keyframes main-stage-right-curtain-animate {
    100% {
        transform: translateX(100%);
        background-color: #111722d5;
    }
}

@keyframes main-stage-vertical-curtain-frame-animate {
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        transform: rotate3d(0, 1, 0, 30deg);
        opacity: 1;
    }
}

@keyframes main-stage-horizontal-curtain-frame-animate {
    0% {
        transform: rotate3d(1, 0, 0, 0deg) translateY(10px);
    }

    100% {
        transform: rotate3d(1, 0, 0, 30deg) translateY(10px);
    }
}

@keyframes mio-stage-curtain-animate-show {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }
}

@keyframes hide-curtain-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.mio-info-panel {
    width: 312px;
    height: 200px;
    margin-top: -96px;
    position: absolute;
    border: 2px dashed #8b0517;
    box-shadow: 0 0 12px #b20c26;
    animation: mio-info-panel-animation 6s linear infinite;
}

@keyframes mio-info-panel-animation {
    0%, 80% {
        border: 2px dashed #8b0517;
        box-shadow: 0 0 12px #b20c26;
    }

    81% {
        border: 2px dashed transparent;
        box-shadow: 0 0 12px transparent;
    }

    82%, 83% {
        border: 2px dashed #8b0517;
        box-shadow: 0 0 12px #b20c26;
    }

    84% {
        border: 2px dashed transparent;
        box-shadow: 0 0 12px transparent;
    }

    85%, 100% {
        border: 2px dashed #8b0517;
        box-shadow: 0 0 12px #b20c26;
    }
}


.mio-info-panel>span {
    font-size: 14px;
    font-weight: bold;
}

.mio-info-panel>.score {
    color: #d7e5ee;
    text-shadow: 0 0 1px #d7e5ee;
    position: absolute;
    left: 0;
    margin: 10px 15px;
    animation: score-highlight-animation 2.4s linear infinite;
}

.mio-info-panel>.score:active {
    opacity: 1;
}

.mio-info-panel>.name {
    color: white;
    text-shadow: 0 0 1px white;
    position: absolute;
    right: 0;
    margin: 10px 15px;
}

@keyframes score-highlight-animation {
    0%, 70% {
        opacity: 0.7;
    }

    71%, 100% {
        opacity: 1;
    }
}

.welcome-action {
    animation: welcome-action-animation 1s linear;
}

@keyframes welcome-action-animation {
    0% {
        opacity: 0;
    }

    80%{
        opacity: 0.5;
    }
}

@media (max-width: 712px) {
    .welcome-message{
        width: 295px;
    }
}
.welcome-action .navigation-button {
    opacity: 1;
}
