.welcome-message-container {
    position: relative;
}

.welcome-message {
    margin-top: 20px;
    width: 480px;
    height: 70px;
    z-index: 10;
    border-radius: 10px;
    background-color: #0b111ce3;
    color: #d7e5ee;
    padding: 10px;
    font-size: 14px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    line-height: 60px;
    animation: welcome-message-border-animation 8s linear infinite;
}

.the-mion-family-tag {
    width: 170px;
    height: 20px;
    z-index: 10;
    position: absolute;
    bottom: -24px;
    right: 25px;
    border: 6px solid #4d4fafa8;
    border-image: linear-gradient(to top, #161245b6, #4d4fafa8) 30 stretch;
    border-image-slice: 1;
    background-color: #0b111c;
    color: white;
    padding: 5px;
    font-size: 14px;
    white-space: nowrap;
    text-shadow: 0 0 2px white;
}

.the-mion-family-tag img {
    position: absolute;
    top: 2px;
    right: -25px;
    transform: rotate(340deg);
}

@keyframes welcome-message-border-animation {
    0%, 80% {
        border: 2px dashed #7b96d5;
        box-shadow: 0 0 12px #7b96d5;
    }

    81% {
        border: 2px dashed transparent;
        box-shadow: 0 0 12px transparent;
    }

    82%, 83% {
        border: 2px dashed #7b96d5;
        box-shadow: 0 0 12px #7b96d5;
    }

    84% {
        border: 2px dashed transparent;
        box-shadow: 0 0 12px transparent;
    }

    85%, 100% {
        border: 2px dashed #7b96d5;
        box-shadow: 0 0 12px #7b96d5;
    }
}
