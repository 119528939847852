.App {
  text-align: center;
}

.container {
  background-color: #0b111c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.container>div {
  width: 100%;
}
