:root {
    --mio-frame-A: url("../../../assets/prop/mio/Mio\ on\ Stage\ 1A.png");
    --mio-frame-B: url("../../../assets/prop/mio/Mio\ on\ Stage\ 1B.png");
    --mio-frame-C: url("../../../assets/prop/mio/Mio\ on\ Stage\ 1C.png");
}

#mio {
    width: 140px;
    height: 180px;
    animation-name: mio-animate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background-repeat: round;
    background-image: var(--mio-frame-A);
    margin: 20px 50%;
    position: absolute;
    bottom: 5px;
    right: -70px;
    margin-left: calc(50% - 60px);
}

@keyframes mio-animate {
    0%, 33% {
        background-image: var(--mio-frame-A);
    }

    34%, 66% {
        background-image: var(--mio-frame-B);
    }

    67%, 100% {
        background-image: var(--mio-frame-C);
    }
}
