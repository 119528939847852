.layout-container{
    margin-top: 50px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.layout-container>div{
    margin: 10px;
}
