.loading-container {
    width: 250px;
    height: 15px;
    background-image: url("../../../assets/prop/welcome/loading-bar-holder.png");
    overflow: hidden;
    padding: 10px;
}

.loading-bar {
    width: 250px;
    height: 17px;
    background-image: url("../../../assets/prop/welcome/loading-bar-progress.png");
    animation: progress-animate 5s linear 1;
    box-shadow: 0 0 6px yellow;
}

@keyframes progress-animate {
    0% {
        width: 0%;
    }
    29% {
        width: 0%;
    }
    30% {
        width: 15%;
    }
    49% {
        width: 15%;
    }
    50% {
        width: 60%;
    }
    69% {
        width: 60%;
    }
    70% {
        width: 85%;
    }
    99% {
        width: 85%;
    }
    100% {
        width: 100%;
    }
}

@keyframes end-progress-animate {
    100% {
        height: 0;
    }
}

@keyframes hidden-animate {
    0% {
        height: 0;
    }
    100% {
        height: 0;
    }
}
