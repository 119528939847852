.miofa-with-name {
    width: 70px;
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name-container {
    min-width: 90px;
    min-height: 20px;
    position: absolute;
    top: -40px;
    border: 6px solid #4d4fafa8;
    border-image: linear-gradient(to top, #161245b6, #4d4fafa8) 30 stretch;
    border-image-slice: 1;
    background-color: #0b111c;
    color: white;
    padding: 5px;
    font-size: 14px;
    overflow: hidden;
    white-space: initial;
    text-shadow: 0 0 2px white;
}

.big .name-container {
    left: 10px;
}

.extra-big .name-container {
    top: -15px;
    min-width: 120px;
    left: -15px;
}

.miofa-with-name>.miofa {
    z-index: 0;
}
