:root {
    --miofa-frame1: url("../../../assets/prop/fan/MiochunRig.png");
    --miofa-frame2: url("../../../assets/prop/fan/MiochunRig2.png");
    --miofa-headband-frame1: url("../../../assets/prop/fan/MiochunRig-HeadBand.png");
    --miofa-headband-frame2: url("../../../assets/prop/fan/MiochunRig-HeadBand2.png");
    --miofa-lightstick-frame1: url("../../../assets/prop/fan/MiochunRig-LightStick.png");
    --miofa-lightstick-frame2: url("../../../assets/prop/fan/MiochunRig-LightStick2.png");
}

.miofa-container {
    margin: -10px;
    margin-top: -30px;
    position: relative;
    animation-name: miofa-spawn, miofa-animate;
    animation-duration: 1s, 0s;
    animation-delay: 0s, 1s;
    animation-timing-function: linear, linear;
    animation-iteration-count: 1, infinite;
}

@keyframes miofa-spawn {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }

    80% {
        opacity: 0.5;
    }
}

@keyframes miofa-despawn {
    80% {
        opacity: 0.5;
    }

    100% {
        transform: translateY(10px);
    }
}

@keyframes miofa-animate {
    0% {
        transform: none;
    }

    49% {
        transform: none;
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

.miofa {
    width: 90px;
    height: 90px;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.miofa.normal {
    animation-name: miofa-normal-animate;
}

.miofa.headband {
    animation-name: miofa-headband-animate;
}

.miofa.lightstick {
    animation-name: miofa-lightstick-animate;
}

@keyframes miofa-normal-animate {
    0% {
        background-image: var(--miofa-frame1);
    }

    49% {
        background-image: var(--miofa-frame1);
    }

    50% {
        background-image: var(--miofa-frame2);
    }

    100% {
        background-image: var(--miofa-frame2);
    }
}


@keyframes miofa-headband-animate {
    0% {
        background-image: var(--miofa-headband-frame1);
    }

    49% {
        background-image: var(--miofa-headband-frame1);
    }

    50% {
        background-image: var(--miofa-headband-frame2);
    }

    100% {
        background-image: var(--miofa-headband-frame2);
    }
}

@keyframes miofa-lightstick-animate {
    0% {
        background-image: var(--miofa-lightstick-frame1);
    }

    49% {
        background-image: var(--miofa-lightstick-frame1);
    }

    50% {
        background-image: var(--miofa-lightstick-frame2);
    }

    100% {
        background-image: var(--miofa-lightstick-frame2);
    }
}


.debug-indicator {
    position: absolute;
    color: black;
    width: 100%;
    bottom: 42px;
}
