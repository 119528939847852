.backdrop {
    width: 100%;
    height: 467px;
    position: relative;
}

.backdrop-play-logo-container {
    position: absolute;
    width: 1000px;
    height: 100%;
    overflow: hidden;
    margin: 0 24%;
}

.backdrop-play-logo-container>.backdrop-play-logo {
    position: absolute;
    animation: backdrop-play-logo-blink-animation 8s infinite ease,
        backdrop-play-logo-move-animation 6s infinite ease-in;
    bottom: -20%;
}

@keyframes backdrop-play-logo-move-animation {
    0% {
        transform: translateY(0%) rotate(0deg);
    }

    80% {
        transform: translateY(-150%) rotate(288deg);
    }

    96%, 100% {
        transform: translateY(-200%) rotate(360deg);
        opacity: 0;
    }
}

@keyframes backdrop-play-logo-blink-animation {
    0%, 10% {
        opacity: 0;
    }

    30% {
        opacity: .8;
    }

    50%, 100% {
        opacity: 0;
    }
}

@media (max-width: 1322px) {
    .backdrop-play-logo-container {
        width: 100%;
        margin: 0;
    }
}
