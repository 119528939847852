.mio-stage {
    width: 100%;
    height: 467px;
    position: relative;
    background-image: url("../../assets/stages/mio/stage-top.png");
    background-repeat: no-repeat;
    background-size: 1354px 467px;
    background-position: center;
    box-sizing: border-box;
    color: black;
}
