.main-stage-container {
    min-height: calc(100vh - 467px);
    width: 100%;
    background-color: #0b111c;
    background-image: url("../../assets/stages/main/tile.png");
    background-position: center 188px;
    overflow: hidden;
}

@media (max-width: 712px) {
    .main-stage-container {
        padding-bottom: 50px;
    }
}
