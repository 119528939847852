.pagination-button {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: round;
    margin-bottom: 8px;
}

.pagination-button.disabled {
    cursor: not-allowed;
    opacity: .5;
}

.pagination-button.next {
    background-image: url("../../assets/pagination/right.png");
}


.pagination-button.previous {
    background-image: url("../../assets/pagination/left.png");
}
